<script lang="ts" setup>
import { useWallet } from 'solana-wallets-vue'
import { JSTAKING_VOTE } from '~/config'
import type { StakeAccount } from '~/store'
import { useStakeAccountStore } from '~/store'

const wallet = useWallet()
const { connected } = wallet

const stakeAccountStore = useStakeAccountStore()
const loadingPubkeys = computed(() => stakeAccountStore.loadingPubkeys)

const validatorsStore = useValidatorsStore()
const getValidator = (voteId: string) => validatorsStore.allValidators.find(v => v.voteId === voteId)

const accountsFull = computed(() => {
  return stakeAccountStore.accountsFull
    .map((acc) => {
      return {
        ...acc,
        validator: getValidator(acc.stakeAccount.account.data?.parsed?.info?.stake?.delegation?.voter),
      }
    })
    .filter(acc => acc.state === 'active' && acc.validator && acc.validator.voteId !== JSTAKING_VOTE && acc.validator.inJpool)
})

const loading = computed(() => stakeAccountStore.loading)
async function depositJpool(stakeAccount: StakeAccount) {
  stakeAccountStore.depositJpool([stakeAccount])
}
</script>

<template>
  <section>
    <div class="container">
      <redelegate-skeleton v-if="loading" />
      <div v-else class="row justify-center">
        <div class="relative-position col-12 col-md-6">
          <div class="column justify-center items-center q-pb-sm">
            <div class="redelegation__title">
              {{ $t('smartStaking.redelegation.title') }}
            </div>
            <div class="redelegation__subtitle">
              {{ $t('smartStaking.redelegation.subtitle') }}
            </div>
          </div>

          <div v-if="!connected" class="redelegation__subtitle text-center text-weight-medium text-body1">
            {{ $t('smartStaking.redelegation.notConnected') }}
          </div>

          <div v-else class="full-width">
            <q-list v-if="accountsFull.length > 0" separator class="q-mt-sm">
              <stake-item-redelegate
                v-for="(acc) in accountsFull" :key="acc.stakeAccount.pubkey.toBase58()"
                class="q-mb-md"
                :stake-account="acc.stakeAccount"
                :validator="acc.validator"
                :loading="loadingPubkeys.has(acc.stakeAccount.pubkey.toBase58())"
                @deposit-jpool="depositJpool"
              />
            </q-list>

            <div v-else class="redelegation__subtitle text-center text-weight-medium text-body1">
              {{ $t('smartStaking.redelegation.noAccounts') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
