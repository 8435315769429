<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    texts: {
      type: Array as PropType<Array<{ text: string, isBold?: boolean }>>,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {}
  },
})
</script>

<template>
  <a class="faq-special__item" :href="link" target="_blank">
    <div class="faq-special__desc">
      <div class="faq-special__title">
        {{ title }}
      </div>
      <div class="faq-special__text">
        <span v-for="text in texts" :key="text.text" :class="{ 'text-weight-bold': text.isBold }">{{
          text.text
        }}</span>
      </div>
    </div>
    <div class="faq-special__icon">
      <slot name="icon" />
    </div>
  </a>
</template>
